<template>
	<el-row class="detailsList">
		<el-row class="detailsList-title">
			{{noticesdata.title}}
		</el-row>
		<el-row class="detailsList-date">
			<span>{{noticesdata.time}}</span>
			<span>{{noticesdata.month}}</span>
			<span>{{noticesdata.year}}</span>
		</el-row>
		<el-row class="detailsList-article" v-html="noticesdata.bodyContent">
			{{noticesdata.bodyContent}}
		</el-row>
	</el-row>
</template>

<script>
	export default
	{
		data()
		{
			return{
				// 数据
				noticesdata:{},
				time:[]
			}
		},
		created()
		{
			this.newsHeadTitle=this.$route.query.newsHeadTitle
			this.noticesdata=JSON.parse(localStorage.getItem('Details'))
			console.log(this.noticesdata)
			if(this.noticesdata.publishTime!=null)
			{
				this.time=this.noticesdata.publishTime;
				this.times()
			}
		},
		methods:
		{
			times()
			{
				this.time=this.time.split('-')
				if(this.time[1]=='01')
				{
					this.time[1]='JAN'
				}
				else if(this.time[1]=='02')
				{
					this.time[1]='FEB'
				}
				else if(this.time[1]=='03')
				{
					this.time[1]='MAR'
				}
				else if(this.time[1]=='04')
				{
					this.time[1]='APR'
				}
				else if(this.time[1]=='05')
				{
					this.time[1]='MAY'
				}
				else if(this.time[1]=='06')
				{
					this.time[1]='JUN'
				}
				else if(this.time[1]=='07')
				{
					this.time[1]='JUL'
				}
				else if(this.time[1]=='08')
				{
					this.time[1]='AUG'
				}
				else if(this.time[1]=='09')
				{
					this.time[1]='SEP'
				}
				else if(this.time[1]=='10')
				{
					this.time[1]='OCT'
				}
				else if(this.time[1]=='11')
				{
					this.time[1]='NOV'
				}
				else if(this.time[1]=='12')
				{
					this.time[1]='DEC'
				}
				var date=this.time[2].split(' ')
				this.noticesdata.year=this.time[0];
				this.noticesdata.month=this.time[1];
				this.noticesdata.time=date[0];
				console.log(this.noticesdata,9999999)
			},
		}
	}
</script>

<style>
	.detailsList{
		width: 100%;
		text-align: center;
	}
	.detailsList-title{
		margin-top: 10px;
		font-size: 30px;
		color: #505050;
		line-height: 60px;
	}
	.detailsList-date{
		margin-top: 10px;
		color: #666;
		
		font-size: 24px;
		line-height: 60px;
	}
	.detailsList-date span{
		margin-left: 20px;
	}
	.detailsList-imgbox{
		margin-top: 20px;
	}
	.detailsList-img{
		width: 100%;
		height: 400px;
	}
	.detailsList-article{
		margin-top: 20px;
		line-height: 30px;
		word-wrap:break-word;
		white-space: pre-wrap;
		display: inline-block;
		text-align: justify;
		text-justify: distribute-all-lines;
		
		color: #505050;
		line-height: 48px;
	}
	.detailsList-article::v-deep img{
		max-width:100%;
	}
	.detailsList-article p{
		width: 100%;
		word-wrap:break-word;
		white-space: pre-wrap;
	}
	.detailsList-article img{
		max-width: 100%;
	}
	.detailsList-article iframe{
		max-width: 100%;
	}
</style>