<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<!-- 公告详情 -->
		<el-row class="list">
			<noticesdetailsdata></noticesdetailsdata>
		</el-row>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>

// 头部组件
import headLogo from '@/components/headLogo.vue'
// 头部导航
import navigation from '@/components/navigation.vue'
// 底部导航
import bottomNavigation from '@/components/bottomNavigation.vue'
// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
// 公告详情
import noticesdetailsdata from '@/components/details/noticesdetailsdata.vue'
export default
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'BREAKING NEWS & NOTICES',
			// 头部导航图片
			headImg:'/index/notice.png',
			// 导航值
			navigationvalue:false,
			
			// 数据
			noticesdata:{}
		}
	},
	components:
	{
		headLogo,
		navigation,
		bottomNavigation,
		noticesdetailsdata,
		meauDialog
	},
	created()
	{
		this.headTitle=this.$route.query.headTitle;
		this.headImg=this.$route.query.headImg;
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		}
	}
}

</script>

<style scoped>
	.list{
		width: 60%;
		margin-left: 20%;
	}
</style>